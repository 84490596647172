import React from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql} from 'gatsby'

const CatalogoImage = (isEn) => {
    const imageQuery = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "catalogo"}}) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              gatsbyImageData(width: 300)
            }
            extension
          }
        }
      }
    }    

  `)
  
  const images = imageQuery.allFile.edges.map((image) => image.node)

  return(
    <>
        <div className='sm:container px-5 md:p-0 w-full flex flex-col bg-primary/0 relative z-10 backdrop-blur-sm mx-auto'>
            <div className='flex flex-col 2xl:mx-64 sm:mx-72 mx-0 mt-20 bg-white/0 flex-wrap'>
                <h2 className='text-center text-3xl font-bold'>{isEn == true? "CATALOGUE IMAGES" : "CATALOGO DE IMAGENES"}</h2>
            </div>  
          <div className='flex flex-col xl:flex-row 2xl:mx-64 lg:mx-32 justify-center mx-0 my-20 bg-white/0 flex-wrap'>
            
            {
              images.map((image, index)=>(
                <>
                  <div className='basis-1 lg:basis-1/2 w-full flex flex-col z-20 relative move-left my-5 p-5 items-center text-center'>
                    <div key={index} >
                    <GatsbyImage image={getImage(image)} alt={image.name} />
                    <br />
                    <p className='font-bold text-xl mt-5'>{image.name}</p>
                    </div>
                  </div>
                </>
              ))
            }
            
          </div>
            
        </div>
    </>
  )
}

export default CatalogoImage