import Layout from '../../components/layout/LayoutComponentEn'
import React from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql} from 'gatsby'
import CatalogoImage from '../../components/CatalogoImage';

const IndexPage = () => {
  const dataQuery = useStaticQuery(graphql`
    query {
      allFile(filter: {sourceInstanceName: {eq: "pdfs"}}) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              gatsbyImageData(width: 300)
            }
            extension
          }
        }
      } 
    }

  `)

  const pdfs = dataQuery.allFile.edges.map((pdf) => pdf.node);

  return (
    <Layout pageTitle="Cataloge" content='' isHome={true}>
      <>
      <div className='sm:container px-5 md:p-0 w-full flex flex-col bg-primary/0 relative z-10 backdrop-blur-sm mx-auto'>
            <div className='flex flex-col 2xl:mx-64 sm:mx-72 mx-0 mt-20 bg-white/0 flex-wrap'>
                <h2 className='text-center text-3xl font-bold'>CATALOGUE PDFs</h2>
            </div>  
          <div className='flex flex-col xl:flex-row 2xl:mx-64 lg:mx-32 justify-center mx-0 my-20 bg-white/0 flex-wrap'>
            
            {
              pdfs.filter(pdf => pdf.extension == "pdf").map((pdf, index)=>(
                <>
                  <div className='basis-1 lg:basis-1/2 w-full flex flex-col z-20 relative move-left my-5 p-5 items-center text-center'>
                    <a key={index} href={pdf.publicURL} target="_blank" >
                      <GatsbyImage image={getImage(pdfs.filter(img => img.name == pdf.name && img.extension != "pdf")[0])} alt={pdf.name} />
                    <br />
                    <p className='font-bold text-xl mt-5'>{pdf.name}</p>
                    </a>
                  </div>
                </>
              ))
            }
            
          </div>
            
        </div>
        <CatalogoImage isEn={true}/>
      </>
    </Layout>
  )
}
export default IndexPage

export const Head = () => (
  <>
    <title>Contactanos | NecarMed</title>
  </>
)
